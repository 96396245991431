<template>
    <v-container class="fill-height">
        <v-responsive class="d-flex align-center text-center fill-height">
    
        
            <v-icon size="115" color="green-accent-4" class="mb-3">mdi-check-circle</v-icon>
    
            <h1 class="my-today-title-text font-weight-bold text-blue-darken-4"> {{ $t('finish_register') }} </h1>

            <p class="my-new-text text-blue-darken-4">{{ $t('etc1') }}</p>
            <p class="my-new-text text-blue-darken-4">{{ $t('etc2') }}</p>

        <v-col cols="12"></v-col>

         
            <v-img height="300" :src="data.hiv_photo" />
            <p class="my-new-text font-weight-bold mb-5 mt-5 text-blue-darken-4"> {{ $t('name') }} : {{ data.firstname }}  {{ data.lastname }}</p>
            <p class="my-new-text font-weight-bold mb-5 text-blue-darken-4"> {{ $t('meet') }} : {{ data.company }}</p>


            <v-card color="blue-darken-4" @click="back()" height="60%" width="100%" class="clickable-card mt-5 rounded-xl">
            <v-card-title>
                <!-- ใช้ text-start เพื่อชิดซ้าย -->
                <v-col cols="12">
                    <p class="my-btn-text">{{ $t('back') }}</p>
                </v-col>
            </v-card-title>
        </v-card>
    
            
    
            
        </v-responsive>
    </v-container>
    </template>
    
    <script>
    import {
    mapState
} from 'vuex';
import {
    useRouter
} from 'vue-router';

export default {

    data() {

        return {
            title: "ลงทะเบียน",
            description: "สำหรับผู้ที่ต้องการเข้าพื้นที่",
            data_temperature_text: "เมฆครึมฟ้าครีมฝน",
            data_temperature: "27°c",
            data_air_text: "คุณภาพอากาศ",
            data_air: "ดีมาก",
            Date_data: "วันจันทร์ที่ 1 มกราคม 2567",
            time: "00:00",
            longitude: "",
            latitude: "",
            language: "th"
        }
    },

    methods: {
        back() {
            this.data.id_card = ""
            this.data.firstname = ""
            this.data.lastname = ""
            this.data.company = ""
            this.data.hiv_photo = ""
            this.data.register_status = ""
            this.data.id_photo = ""
        this.data.lang = ""

            this.$store.dispatch("datas/reset")
            //   การจัดการเหตุการณ์เมื่อคลิกที่ Card
            this.$router.push({
                name: 'Homevisitor'
            });
            console.log('Card ถูกคลิก');
        }
    },
    mounted() {
        // เรียกใช้งานเมื่อ component ถูกติดตั้ง
        setTimeout(() => {
      this.back();
    }, 5000)
    },

    computed: {
        ...mapState('datas', {
            data: 'data'
        })
    }
}
    </script>
    