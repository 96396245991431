import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import cardreader from '../views/Card.vue'
import register from '../views/Register.vue'
import registerresult from '../views/Result.vue'
import Homevisitor from '../views/Main.vue'

const routes = [
  {
    path: '/',
    name: 'Homevisitor',
    component: Homevisitor
  },
  {
    path: '/cardreader',
    name: 'cardreader',
    component: cardreader
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/registerresult',
    name: 'registerresult',
    component: registerresult
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
