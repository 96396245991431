<template>
<v-container class="fill-height">
    <v-responsive class="d-flex align-center text-center fill-height">
        <v-dialog v-model="sleep" transition="dialog-bottom-transition" fullscreen>
            <v-card color="black" @click="sleep =false">
            </v-card>
        </v-dialog>

        <v-row v-if="data.lang == '' ">
            <v-col cols="6">
                <v-container fluid>
                    <v-card flat class="mb-3" @click="changelang('TH')">

                        <v-img height="300" class="mb-3" src="@/assets/thailand.png" />
                        <v-card-title class="text-blue-darken-4 my-lang-text font-weight-bold">ภาษาไทย</v-card-title>

                    </v-card>

                </v-container>
            </v-col>
            <v-col cols="6">
                <v-container fluid>
                    <v-card flat class="mb-3" @click="changelang('EN')">

                        <v-img height="300" class="mb-3" src="@/assets/unitedkingdom.png" />
                        <v-card-title class="text-blue-darken-4 my-lang-text font-weight-bold">English</v-card-title>

                    </v-card>

                </v-container>
            </v-col>
        </v-row>

        <v-row v-if="data.lang != '' ">
            <v-col cols="6">
                <v-img height="150" src="https://wtc.co.th/wp-content/uploads/wtc-logo-NEW-2020-01-300x127.png" />
            </v-col>
            <v-col cols="6">
                <h1 class="text-end text-blue-darken-4 my-time-text">{{ currentTime }}</h1>
                <h1 class="text-end text-blue-darken-4 mt-n4 mb-2 my-date-text">{{ currentDate }}</h1>
            </v-col>
        </v-row>

        <v-card v-if="data.lang != '' " rounded="x-large" color="blue-darken-4" @click="handleClick()" height="50%" class="clickable-card mb-3 rounded-xl">
            <v-card-title class="text-start">
                <!-- ใช้ text-start เพื่อชิดซ้าย -->
                <v-row class="mt-3 mb-3">
                    <v-col cols="3" class="ml-12">
                        <v-icon size="100">mdi-pencil</v-icon> <!-- กำหนดขนาดเป็น 24px -->
                    </v-col>
                    <v-col cols="9" class="ml-n12">
                        <h1 class="my-today-text mb-5 mt-5">{{ $t('register') }}</h1>
                        <p class="mt-5 my-new-text">{{ $t('register_desc') }}</p>
                    </v-col>
                </v-row>
            </v-card-title>

        </v-card>

        <v-divider v-if="data.lang != '' " class="mt-5"></v-divider>

        <v-row v-if="data.lang != '' ">
            <v-col cols="12">
                <v-container fluid>
                    <v-card flat class="mb-3">
                        <!-- <v-img v-if="news.thumbnail" :src="getThumbnailUrl(news.thumbnail.imagePath)" /> -->
                        <!-- <v-card-title class="text-blue-darken-4 my-new-text mb-3 font-weight-bold">{{ data.list_subittle_news[0].subTitle }}</v-card-title> -->
                        <!-- <v-img height="1000" class="mb-2" src="@/assets/registor-01.jpg" /> -->
                        <!-- <v-card-subtitle>{{ formatDate(news.publishDate) }}</v-card-subtitle> -->
                        <v-carousel height="1000" show-arrows="hover" cycle :interval="13000" hide-delimiter-background>
                            <v-carousel-item v-for="(slide, i) in data.list_subittle_news" :key="i">
                                <v-card-title class="text-blue-darken-4 my-title-text mb-3 font-weight-bold">{{ data.list_subittle_news[i].title }}</v-card-title>
                                <!-- <v-img :src="colors[i]" height="100%">

                                </v-img> -->
                                <v-img height="100%" class="mb-2" :src="data.url_lifelink + data.list_subittle_news[i].thumbnail.imagePath" />
                            </v-carousel-item>
                        </v-carousel>
                    </v-card>

                </v-container>
            </v-col>
        </v-row>

        <v-divider v-if="data.lang != '' " class=""></v-divider>

        <v-row class="" v-if="data.lang != '' ">
            <v-col cols="6">
                <v-sheet class="mt-5 rounded-xl" rounded="x-large" :height="100" color="light-blue-darken-1">

                    <v-col class="8 ">
                        <h2 class="my-today-title-text mt-n3">{{ data.today_list.condition }}</h2>
                    </v-col>
                    <v-col class="8">
                        <h1 class="my-today-text mt-n10">{{ data.today_list.temperature }} °C </h1>
                    </v-col>

                </v-sheet>
            </v-col>
            <v-col cols="6">
                <v-sheet v-if="data.pm_list.pm2_5 <= 25" rounded="x-large" :height="100" color="light-blue-darken-1 rounded-xl">

                    <v-col class="8 mt-5">
                        <h1 class="my-today-text">PM 2.5 : {{ data.pm_list.pm2_5 }}</h1>
                    </v-col>
                </v-sheet>
                <v-sheet v-if="data.pm_list.pm2_5 <= 37 && data.pm_list.pm2_5 >= 26" rounded="x-large" :height="100" color="green-lighten-1 rounded-xl">

                    <v-col class="8 mt-5">
                        <h1 class="my-today-text">PM 2.5 : {{ data.pm_list.pm2_5 }}</h1>
                    </v-col>
                </v-sheet>
                <v-sheet v-if="data.pm_list.pm2_5 <= 50 && data.pm_list.pm2_5 >= 38" rounded="x-large" :height="100" color="yellow-darken-1 rounded-xl">

                    <v-col class="8 mt-5">
                        <h1 class="my-today-text">PM 2.5 : {{ data.pm_list.pm2_5 }}</h1>
                    </v-col>
                </v-sheet>
                <v-sheet v-if="data.pm_list.pm2_5 <= 90 && data.pm_list.pm2_5 >= 51" rounded="x-large" :height="100" color="orange-darken-1 rounded-xl">

                    <v-col class="8 mt-5">
                        <h1 class="my-today-text">PM 2.5 : {{ data.pm_list.pm2_5 }}</h1>
                    </v-col>
                </v-sheet>
                <v-sheet v-if="data.pm_list.pm2_5 >= 91" rounded="x-large" :height="100" color="red-darken-1 rounded-xl">

                    <v-col class="8 mt-5">
                        <h1 class="my-today-text">PM 2.5 : {{ data.pm_list.pm2_5 }}</h1>
                    </v-col>
                </v-sheet>
            </v-col>
        </v-row>

        <v-row class="mt-5 d-flex justify-center" v-if="data.lang != '' ">
            <v-col cols="3">
                <p class="my-new-text mt-3">{{ $t('powered_by') }}</p>

            </v-col>
            <v-col cols="3">
                <v-img height="100" class="mt-n3" src="@/assets/main_logo_white.jpeg" />
            </v-col>
            <v-col cols="2">
                <v-btn x-large flat class="my-today-title-text mt-5" @click="backlang()">{{ $t('back_lang') }}</v-btn>
            </v-col>
        </v-row>

        <v-row class="mt-5 d-flex justify-end" v-if="data.lang == ''">
        </v-row>
        <v-layout-item class="text-end" position="bottom" size="80" model-value>
            <v-btn class="ma-4" color="black" elevation="0" outlined icon="mdi-power-sleep" @click="sleep = true"></v-btn>
        </v-layout-item>

    </v-responsive>
</v-container>
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    useRouter
} from 'vue-router';

export default {

    data() {

        return {
            sleep: false,
            title: "ลงทะเบียน",
            description: "สำหรับผู้ที่ต้องการเข้าพื้นที่",
            data_temperature_text: "เมฆครึมฟ้าครีมฝน",
            data_temperature: "27°c",
            data_air_text: "คุณภาพอากาศ",
            data_air: "ดีมาก",
            Date_data: "วันจันทร์ที่ 1 มกราคม 2567",
            time: "00:00",
            longitude: "",
            latitude: "",
            language: "th",
            currentTime: '',
            currentDate: '',
            colors: [
                require('@/assets/IMG_5974.jpg'), // อ้างอิงไฟล์รูปภาพในโฟลเดอร์ assets ด้วย require()
                require('@/assets/IMG_5973.jpg'),
            ],
            slides: [
                'First',
                'Second',
            ],
            currentIndex: 0,
        }
    },

    created() {
        const now = new Date();
        const result = now.toLocaleDateString('th-TH', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            newsPosition: 0,
            changeNewsInSecond: 10, // หน่วยเป็นวินาที
            newsTimer: null
        })

        this.Date_data = result

    },

    methods: {
        updateTime() {
            const now = new Date();
            this.currentTime = now.toLocaleTimeString('th-TH', {
                hour: 'numeric',
                minute: 'numeric',
                // second: 'numeric'
            });
        },

        updateDate() {
            const now = new Date();
            this.currentDate = now.toLocaleDateString('th-TH', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        },
        changelang(lang) {
            this.$i18n.locale = lang
            this.data.lang = lang
        },
        backlang() {
            this.data.lang = ""
        },
        handleClick() {
            //   การจัดการเหตุการณ์เมื่อคลิกที่ Card
            this.$router.push({
                name: 'cardreader'
            });
            console.log('Card ถูกคลิก');
        },
        runNews() {
            this.newsTimer = setInterval(() => {
                if (this.newsList && this.newsList.length > 0) {
                    if (this.newsPosition < this.newsList.length - 1) {
                        this.newsPosition++;
                    } else {
                        this.newsPosition = 0;
                    }
                }
            }, 1000 * this.changeNewsInSecond);
        },
        getThumbnailUrl(imagePath) {
            // อาจต้องแก้ไข URL รูปภาพตามที่คุณต้องการ
            return `${this.data.url_lifelink}${imagePath.substring(1)}`;
        },
        formatDate(dateString) {
            // อาจต้องแปลงวันที่ตามที่คุณต้องการ
            return new Date(dateString).toLocaleDateString();
        },

        fetchNews() {
            this.$store.dispatch("datas/fetchNews")

        },
        today_data() {
            this.$store.dispatch("datas/today_data")

        },
        startAutoSlide() {
            setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.slides.length;
                console.log(this.currentIndex)
            }, 10000); // เปลี่ยนทุก 5 วินาที
        },

    },

    mounted() {
        // เรียกใช้งานเมื่อ component ถูกติดตั้ง
        this.updateTime();
        // อัปเดตเวลาทุกๆ 1 วินาที
        setInterval(() => {
            this.updateTime();
        }, 1000);

        this.updateDate();
        // this.fetchNews();

        this.today_data();
        this.fetchNews();

    },

    computed: {
        ...mapState('datas', {
            data: 'data'
        })
    }

}
</script>

<style>
.my-new-text {
    font-size: 30px;
}

.my-btn-text {
    font-size: 20px;
}

.my-time-text {
    font-size: 80px;
}

.my-date-text {
    font-size: 35px;
}

.my-today-title-text {
    font-size: 25px;
}

.my-today-text {
    font-size: 50px;
}

.my-lang-text {
    font-size: 45px;
}

.my-title-text {
    font-size: 40px;
}
</style>
