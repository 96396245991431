<template>
<v-container class="fill-height">
    <v-responsive class="d-flex align-center text-center fill-height">

        <v-img contain height="500" src="@/assets/insert_card.png" />

        <h1 class="my-text font-weight-bold mt-7 text-blue-darken-4">{{ $t('insert_card') }}</h1>
        <h2 class="my-sub-text font-weight-bold mt-5 text-blue-darken-4">{{ $t('insert_card_desc') }}</h2>

        <v-row>
            <v-col cols="12">

                <p class="my-sub-text mt-7 text-blue-darken-4" :style="{ color: socketMessageColor }" id="socket-message">{{ socketMessage }}</p>

                <p class="my-sub-text mt-7 text-blue-darken-4">{{ agent_text_status }}</p>

                <p class="my-sub-text mt-7 text-blue-darken-4">{{ text_status }}</p>

                <p class="my-sub-text mt-7 text-blue-darken-4">{{ card_text_status }}</p>

                <v-label ref="readerSelected" class="TextInput" style="font-weight: bold" id="ReaderSelected">
                    {{ selectedReader }}
                </v-label>

            </v-col>

            <!-- <v-btn color="primary" @click="getReaderlist()">Get Reader List</v-btn> -->

            <!-- List of readers -->
            <!-- <v-select v-model="selectedReader" :items="readerList" label="Reader List" dense></v-select> -->
        </v-row>

        <v-card color="blue-darken-4" @click="handleClick()" height="60%" class="clickable-card mb-5 rounded-xl">
            <v-card-title>
                <!-- ใช้ text-start เพื่อชิดซ้าย -->
                <v-col cols="12">
                    <p class="my-sub-text ">{{ $t('skip') }}</p>
                </v-col>

            </v-card-title>
        </v-card>

        <v-card color="red-darken-4" @click="cancleClick()" height="60%" width="100%" class="clickable-card rounded-xl">
            <v-card-title>
                <!-- ใช้ text-start เพื่อชิดซ้าย -->
                <v-col cols="12">
                    <p class="my-sub-text">{{ $t('cancel') }}</p>
                </v-col>
            </v-card-title>
        </v-card>

    </v-responsive>
</v-container>
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    useRouter
} from 'vue-router';

export default {

    data() {

        return {
            socketMessage: "", // Initialize socketMessage variable
            wSocket: null,
            intendPresent_flag: true,
            debugFlag: true,
            text_status: "",
            card_text_status: "",
            agent_text_status: "",
            selectedReader: null,
            readerList: [],
            selectedReader: "",
        }
    },

    created() {

    },

    methods: {

        handleClick() {
            //   การจัดการเหตุการณ์เมื่อคลิกที่ Card
            this.$router.push({
                name: 'register'
            });
            console.log('Card ถูกคลิก');
        },
        cancleClick() {
            //   การจัดการเหตุการณ์เมื่อคลิกที่ Card
            this.$router.push({
                name: 'Homevisitor'
            });
            console.log('Card ถูกคลิก');
        },

        initWebSocket() {
            this.wSocket = new WebSocket("ws://localhost:14820/TDKWAgent");
            this.wSocket.onopen = () => {
                if (this.debugFlag) {
                    console.log("WebSocket: onopen() event called.");
                }
            };
            this.wSocket.onmessage = (evt) => {
                this.onGetMessage(evt.data);
                console.log("เปิด web soc this.onGetMessage");
            };
            this.wSocket.onclose = () => {
                this.socketMessage = "Agent: No connection";
                if (this.debugFlag) {
                    console.log("WebSocket: onclose() event called.");
                }
            };
            this.wSocket.onerror = () => {
                this.socketMessage = "Agent: No connection";
                if (this.debugFlag) {
                    console.log("WebSocket: onerror() event called.");
                }
            };
        },
        wSocketSend(jsonStr) {
            if (this.debugFlag) {
                console.log(">" + JSON.parse(jsonStr).Command);
            }
            this.wSocket.send(jsonStr);
        },
        getReaderlist() {
            const readerSelect = this.$refs.readerSelected;

            if (readerSelect) {
                console.log(readerSelect)
                readerSelect.innerHTML = "";
                readerSelect.style.color = "black";
                const JS_OBJ = {
                    Command: "GetReaderList",
                };
                const jsonStr = JSON.stringify(JS_OBJ);
                this.wSocketSend(jsonStr);
            } else {
                console.error("readerSelect is undefined");
            }
        },

        getAutoReadOptions() {
            const commandObject = {
                Command: "GetAutoReadOptions",
            };
            const jsonString = JSON.stringify(commandObject);
            this.wSocketSend(jsonString);
        },

        setAutoReadOptions() {

            console.log("เข้า setAutoReadOptions")
            // Get the checkbox
            const autoReadCheckbox = true;
            const idNumberCheckbox = true;
            const idTextCheckbox = true;
            const idATextCheckbox = true;
            const idPhotoCheckbox = true;
            // disabledButton(autoReadCheckbox);

            const commandObject = {
                Command: "SetAutoReadOptions",
                AutoRead: autoReadCheckbox,
                IDNumberRead: idNumberCheckbox,
                IDTextRead: idTextCheckbox,
                IDATextRead: idATextCheckbox,
                IDPhotoRead: idPhotoCheckbox
            };
            const jsonString = JSON.stringify(commandObject);
            this.wSocketSend(jsonString);
        },

        // // Add other methods here

        putToScreen(jsObj) {
            const {
                ID_Number,
                ID_Text,
                ID_AText,
                ID_Photo,
                Status
            } = jsObj;

            if (Status === 0) {

                if (ID_Text !== "") {
                    const NIDData = ID_Text.split("#");

                    this.data.id_card = NIDData[0];
                    this.data.firstname = NIDData[1] + " " + NIDData[2];
                    this.data.lastname = NIDData[4];

                }
                if (ID_AText !== "") {

                        const base64str = ID_Photo;
                        if (base64str !== null) {
                            this.data.id_photo = `data:image/png;base64,${base64str}`;
                        } else {
                            this.data.id_photo = "@/assets/sample_profile.jpeg";
                        }
                    //     if (this.debugFlag) {
                    //         console.log("Reading is finished");
                    //     }
                    // } else {
                    //     if (this.debugFlag) {
                    //         console.log("Read error");
                    //     }
                    //     alert("ERROR Code :" + Status);
                    // }

                    this.$router.push({
                        name: 'register'
                    });
                }
            }

        },

        onGetMessage(jsonString) {
            const msgObj = JSON.parse(jsonString);
            // Handle message from WebSocket
            if (msgObj.Message == "GetReaderListR") {
                if (msgObj.Status > 0) {
                    this.readerList = msgObj.ReaderList;
                } else if (msgObj.Status == -3 || msgObj.Status == 0) {
                    this.readerList = ["Reader not found"];
                } else {
                    alert("ERROR Code :" + msgObj.Status);
                }
            }
            // Handle other message types

            if (msgObj.Message == "AgentStatusE") {
                if (msgObj.Status == 1) {

                    console.log("this.setAutoReadOptions();")
                    this.setAutoReadOptions();
                    console.log("this.getReaderlist()")
                    this.getReaderlist();
                    console.log(msgObj.Message)
                    this.agent_text_status = "Agent: " + msgObj.AgentInfo;
                } else {
                    alert("ERROR Code :" + msgObj.Status);
                }
            }

            if (msgObj.Message === "CardStatusChangeE") {
                if (this.debugFlag) {
                    console.log(
                        "<CardStatusChangeE: " +
                        "(" +
                        msgObj.Status +
                        ")" +
                        " ReaderName: " +
                        "(" +
                        msgObj.ReaderName +
                        ")"
                    );
                }

                if (msgObj.Status === -1004) {
                    alert("ERROR Code :" + msgObj.Status);
                    return;
                } else if (msgObj.Status === 1) {
                    // this.resetTimer();
                    // this.startTimer();

                    this.intendPresentFlag = false;
                    this.text_status = msgObj.ReaderName;
                    this.card_text_status = "Card Status: Present";

                } else if (msgObj.Status === -16) {
                    this.intendPresentFlag = true;

                    this.text_status = msgObj.ReaderName;
                    this.card_text_status = "Card Status: Absent";

                } else if (msgObj.Status === 0) {

                    this.card_text_status = "Card Status: Unknown";

                    this.text_status = msgObj.ReaderName;
                } else {
                    alert("ERROR Code :" + msgObj.Status);
                }
                msgObj.ReaderName = "";
            }

            // if (msgObj.Message === "ReadingProgressE") {
            //     if (msgObj.Status === 0) {
            //         this.$refs.myBar.style.backgroundColor = 'rgb(10, 206, 241)';
            //         this.$refs.myBar.style.width = msgObj.Progress + "%";
            //         this.$refs.lbBar.innerHTML = msgObj.Progress + "%";
            //         if (this.debugFlag) {
            //             console.log(
            //                 "<ReadingProgressE" +
            //                 "(" +
            //                 msgObj.Status +
            //                 "): " +
            //                 msgObj.Progress +
            //                 "%"
            //             );
            //         }
            //     }
            //     if (msgObj.Status === -1) {
            //         this.$refs.myBar.style.backgroundColor = "red";
            //     }
            // }

            if (msgObj.Message === "AutoReadIDCardE") {
                // this.stopTimer();
                this.putToScreen(msgObj);
            }

            if (msgObj.Message == "AutoSelectReaderE") {
                this.text_status = msgObj.ReaderName;
            }

            if (msgObj.Message === "AutoSelectReaderE") {
                this.text_status = msgObj.ReaderName;
            }

            if (msgObj.Message === "GetReaderListR") {
                const readerData = msgObj.ReaderList;
                if (msgObj.Status > 0) {
                    this.readerList = readerData.map((reader, index) => ({
                        text: reader,
                        value: index,
                    }));
                } else {
                    if (msgObj.Status === -3 || msgObj.Status === 0) {
                        this.readerList = [{
                            text: "Reader not found",
                            value: 0
                        }];
                    } else {
                        alert("ERROR Code :" + msgObj.Status);
                    }
                }

            }

            if (msgObj.Message === "SelectReaderR") {
                const readerData = msgObj.ReaderList;
                if (msgObj.Status > 0) {
                    this.readerList = readerData.map((reader, index) => ({
                        text: reader,
                        value: index,
                    }));
                    // Set the selected reader automatically
                    if (this.readerList.length > 0) {
                        this.selectedReader = this.readerList[0].value;
                    }
                } else {
                    if (msgObj.Status === -3 || msgObj.Status === 0) {
                        this.readerList = [{
                            text: "Reader not found",
                            value: 0
                        }];
                    } else {
                        alert("ERROR Code :" + msgObj.Status);
                    }
                }
            }

            if (msgObj.Message === "GetAutoReadOptionsR") {
                if (msgObj.Status === 0) {
                    // this.$refs.chkAutoRead.checked = msgObj.AutoRead;
                    // this.$refs.chkAutoNIDNumber.checked = msgObj.IDNumberRead;
                    // this.$refs.chkAutoNIDText.checked = msgObj.IDTextRead;
                    // this.$refs.chkAutoAText.checked = msgObj.IDATextRead;
                    // this.$refs.chkAutoNIDPhoto.checked = msgObj.IDPhotoRead;
                } else alert("ERROR Code :" + msgObj.Status);
            }

            if (msgObj.Message === "SetAutoReadOptionsR") {
                if (msgObj.Status === 0) {
                    // this.$refs.chkAutoRead.checked = msgObj.AutoRead;
                    // this.$refs.chkAutoNIDNumber.checked = msgObj.IDNumberRead;
                    // this.$refs.chkAutoNIDText.checked = msgObj.IDTextRead;
                    // this.$refs.chkAutoAText.checked = msgObj.IDATextRead;
                    // this.$refs.chkAutoNIDPhoto.checked = msgObj.IDPhotoRead;
                } else alert("ERROR Code :" + msgObj.Status);
            }
        },

    },

    mounted() {
        this.initWebSocket();
        // this.getReaderlist();
        // // this.selectedReader();
        // // this.setAutoReadOptions();
        // this.putToScreen(msgObj);
    },

    computed: {
        ...mapState('datas', {
            data: 'data'
        })
    }

}
</script>

<style>
.my-text {
    font-size: 60px;
}

.my-btn-text {
    font-size: 40px;
}

.my-sub-text {
    font-size: 30px;
}
</style>
