<template>
<v-container class="fill-height">

    <v-responsive class="d-flex align-center text-center fill-height">

        <v-toolbar dark prominent color="blue-darken-4">
            <v-btn @click="back()" icon>
                <v-icon size="30" large>mdi-arrow-left-bold</v-icon>
            </v-btn>

            <v-toolbar-title left>{{ $t('register') }}</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-toolbar>

        <v-alert v-model="snackbar" class="my-new-text mt-3" type="error">{{ $t('alert') }}</v-alert>
        <v-col cols="12"></v-col>

        <v-card v-if="!isCameraOpen && !take_again" color="blue-grey-lighten-2" @click="toggleCamera()" class="clickable-card rounded-xl">
            <v-card-title>
                <v-row class="text-center mt-3 mb-3">
                    <v-col class="text-right" cols="5">
                        <v-icon class="text-left" size="40">mdi-camera</v-icon>
                    </v-col>
                    <v-col cols="3" class=" mt-1">
                        <p class="my-new-text">{{ $t('open_camera') }}</p>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>

        <v-card v-if="!isCameraOpen && take_again" color="blue-grey-lighten-2" @click="toggleCamera()" class="clickable-card rounded-xl">
            <v-card-title>
                <v-row class="text-center mt-3 mb-3 ml-n10">
                    <v-col class="text-right" cols="5">
                        <v-icon class="text-left" size="40">mdi-camera</v-icon>
                    </v-col>
                    <v-col cols="4" class=" mt-1">
                        <p class="my-new-text">{{ $t('open_camera_again') }}</p>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>

        <v-card v-if="isCameraOpen" color="blue-grey-lighten-2" @click="takePhoto()" class="clickable-card rounded-xl">
            <v-card-title>
                <v-row class="text-center mt-3 mb-3">
                    <v-col class="text-right" cols="5">
                        <v-icon class="text-left" size="40">mdi-camera</v-icon>
                    </v-col>
                    <v-col cols="3" class=" mt-1">
                        <p class="my-new-text">{{ $t('take_picture_certificate') }}</p>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>

        <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">

            <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>

            <video v-show="!isPhotoTaken" class="mt-3 mb-3" :width="800" :height="500" ref="video" autoplay playsinline></video>

            <!-- <video v-show="!isPhotoTaken" ref="video"  autoplay></video> -->

            <canvas class="mb-5 mt-5" ref="canvas" id="photoTaken" :width="800" :height="500" style="display: none;"></canvas>
        </div>

        <!-- <video v-show="!isPhotoTaken" class="mt-3 mb-3" ref="video" @canplay="initCanvas()" autoplay playsinline></video>

        <canvas ref="canvas" style="display: none;"></canvas> -->

        <v-img v-if="data.hiv_photo != ''" contain height="500" :src="data.hiv_photo" alt="Photo" />
        <v-img v-if="data.hiv_photo == '' && this.photoTaken3 == false" contain height="500" src="@/assets/idcardsample.png" alt="Photo" />

        <v-row>
            <v-col cols="8">

                <v-col cols="12">
                    <v-text-field class="my-sub-text" v-model="data.id_card" :label="$t('national_id')" :maxlength="13" :rules="textRules"></v-text-field>

                </v-col>
                <v-col cols="12">
                    <v-text-field class="my-sub-text" v-model="data.firstname" :label="$t('name')" :rules="Rules"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field class="my-sub-text" v-model="data.lastname" :label="$t('surname')" :rules="Rules"></v-text-field>
                </v-col>
            </v-col>
            <v-col cols="4">
                <v-img v-if="data.id_photo != ''" v-model="data.id_photo" contain class="mb-5 mt-5" height="300" :src="data.id_photo" alt="Photo" />
                <v-img v-if="data.id_photo == ''" v-model="data.id_photo" class="mb-5" contain height="300" src="@/assets/sample_profile.jpeg" alt="Photo" />
            </v-col>

            <v-col cols="12">
                <v-select v-model="data.company" :items="data.list_contact" :label="$t('select_company')" required :rules="Rules"></v-select>
            </v-col>
        </v-row>

        <v-checkbox size="large" class="mt-10 mb-5" v-model="checkbox">
            <template v-slot:label>
                <h3 class="my-term text-left">
                    {{ $t('accept_term') }}
                </h3>
            </template>
        </v-checkbox>
        <v-progress-circular v-if="data.loading" v-model="loading" indeterminate color="blue-darken-4" size="50"></v-progress-circular>
        <h3 v-if="data.loading" class="my-new-text text-center text-blue-darken-4">
                    {{ $t('wait') }}
                </h3>

        <v-card v-if="!data.loading" color="blue-darken-4" @click="register()" type="submit" height="60%" width="100%" class="clickable-card rounded-xl">
            <v-card-title>
                <!-- ใช้ text-start เพื่อชิดซ้าย -->
                <v-col cols="12">
                    <p class="my-new-text">{{ $t('register') }}</p>

                </v-col>
            </v-card-title>
        </v-card>

    </v-responsive>
</v-container>
</template>

<script>
import {
    mapState
} from 'vuex';
import Camera from "simple-vue-camera";
import {
    ref,
    resolveTransitionHooks
} from 'vue';

export default {
    components: {
        Camera
    },

    data() {

        return {
            checkbox: false,
            snackbar: false,
            company: "",
            firstname: "",
            lastname: "",
            idcard: "",
            video: null,
            photoTaken: false,
            photoTaken2: true,
            video1: 3,
            photoTaken3: false,
            filename: "filephoto1.jpg",
            mimeType: "image/jpeg",
            canvas: null,

            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            take_again: false,
            loading: false,
            link: '#'

        }
    },

    methods: {
        back() {
            //   การจัดการเหตุการณ์เมื่อคลิกที่ Card
            this.data.id_card = ""
            this.data.firstname = ""
            this.data.lastname = ""
            this.data.company = ""
            this.data.hiv_photo = ""
            this.data.id_photo = ""

            this.$router.push({
                name: 'cardreader'
            });
            console.log('Card ถูกคลิก');
        },
        register() {
            if (this.data.id_card != "" && this.data.firstname != "" && this.data.lastname != "" && this.data.company != "" && this.data.hiv_photo != "" && this.checkbox == true ){
                this.data.loading = true
                this.$store.dispatch("datas/register")
                console.log("ออกมา register แล้ว")
            } else {
                this.snackbar = true
                setTimeout(() => {
                    this.snackbar = false
                }, 5000);
            }

        },

        // next() {
        //     if (this.data.register_status != "") {
        //         console.log(this.data.register_status)
        //         this.$router.push({
        //             name: 'registerresult'
        //         });
        //     }
        // },
        listcontect() {
            this.$store.dispatch("datas/list_contact")

        },

        startCamera() {

            console.log(this.video1)
            this.video1 = 2

        },
        stopCamera() {
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
            }
        },

        startCapture() {
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            }).then(stream => {
                this.video.srcObject = stream
                this.video.play()

            }).catch(error => {
                console.log(error)
            })
        },

        initCanvas() {
            this.canvas.setAttribute('width', this.video.videoWidth)
            this.canvas.setAttribute('height', this.video.videoHeight)
        },
        takepicture(items) {
            console.log("takepicture")
            let context = this.canvas.getContext('2d')
            context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight)
            this.$emit('picture-taken', this.canvas.toDataURL('image/png'))
            console.log(this.data.hiv_photo)
            this.video1 = items
        },

        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.takepicture();
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },

        createCameraElement() {
            this.isLoading = true;

            const constraints = (window.constraints = {
                audio: false,
                video: true
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.isLoading = false;
                    this.$refs.video.srcObject = stream;
                })
                .catch(error => {
                    this.isLoading = false;
                    alert("May the browser didn't support or there is some errors.");
                });
        },

        stopCameraStream() {
            let tracks = this.$refs.video.srcObject.getTracks();

            tracks.forEach(track => {
                track.stop();
            });
        },

        takePhoto() {
            if (!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }
            if (!this.take_again) {
                this.take_again = true;
            }

            this.isPhotoTaken = !this.isPhotoTaken;

            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.video, 0, 0, 800, 500);
            // this.data.id_photo = this.$emit('picture-taken', this.$refs.canvas.toDataURL('image/png'))
            this.data.hiv_photo = document.getElementById("photoTaken").toDataURL("image/jpeg")

            this.isCameraOpen = false,
                this.isPhotoTaken = false,
                this.isShotPhoto = false,
                this.isLoading = false,
                console.log(this.data.hiv_photo)

        },

        downloadImage() {
            const download = document.getElementById("downloadPhoto");
            const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream");
            download.setAttribute("href", canvas);
        }
    },

    mounted() {
        // เรียกใช้งานเมื่อ component ถูกติดตั้ง
        this.listcontect();
        this.video = this.$refs.video
        this.canvas = this.$refs.canvas
        this.startCapture()

    },

    watch: {
        token() {
            this.$router.push({
                name: 'registerresult'
            });
        },
    },

    computed: {
        ...mapState('datas', {
            data: 'data'
        }),

        token() {
            return this.$store.state.datas.data.register_status
        },

        numberRules() {
            return [
                v => !!v,
                v => /^\d+$/.test(v)
            ]
        },
        Rules() {
            return [
                v => !!v,
            ]
        },
        textRules() {
            return [
                v => !!v,
                v => (v && v.length >= 7)
            ]
        }
    },
}
</script>

<style>
.my-term {
    font-size: 20px;
}

.v-field {
    font-size: 35px;
}

.v-field {
    font-size: 30px;
}

.v-text-field input {
    font-size: 30px;
}

.v-label {
    font-size: 30px;
}
</style>
