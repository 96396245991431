<template>
  <Homevisitorcompo />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Homevisitorcompo from '../components/Maincompo.vue';

export default defineComponent({
  name: 'Homevisitor',

  components: {
    Homevisitorcompo,
  },
});
</script>