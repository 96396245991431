<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    
  }),
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

#app {
  font-family: 'Kanit', sans-serif;
}

</style>