/* globals Store */
import { Data } from '../../models'
import axios from 'axios';
import VueRouter from 'vue-router';

const state = {
    data: new Data(),
}

const actions = {
        
    list_contact({ commit, state }) {
        // ตรวจสอบให้แน่ใจว่า state.data.url_spare และ state.data.token มีค่าถูกต้อง
        let payload = new FormData()
        payload.append('company_id', "1");
      
        // ใช้ headers ถ้าคุณต้องการส่ง Authorization token
        // const headers = {"Authorization": state.data.token}
      
        axios.post(state.data.url + "listcontact", payload, {
          timeout: 5000 // กำหนด timeout เป็น 5000 มิลลิวินาที (5 วินาที)
        })
          .then((res) => {
            console.log("set")
            if (res.status === 200) {
              if (res.data.status === 200) {
                console.log("listcontect")
                console.log(res.data.data)
                // commit("setloading",false);
                commit("setlist_contact", res.data.data);
              }
              if (res.data.status === 401) {
                console.log("error")
                // commit("setloading",false);
                // commit("seterrorsnack",true);
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            actions.list_contact_wtc({ commit, state })
            // error.response.status Check status code
          })
          .finally(() => {
            // กระทำที่จะทำเสมอ
          });
      },
    
      list_contact_wtc({ commit, state }) {
        // ตรวจสอบให้แน่ใจว่า state.data.url_spare และ state.data.token มีค่าถูกต้อง
        let payload = new FormData()
        payload.append('company_id', "1");
      
        // ใช้ headers ถ้าคุณต้องการส่ง Authorization token
        // const headers = {"Authorization": state.data.token}
      
        axios.post(state.data.url_spare1 + "listcontact", payload, {
          timeout: 5000 // กำหนด timeout เป็น 5000 มิลลิวินาที (5 วินาที)
        })
          .then((res) => {
            console.log("set")
            if (res.status === 200) {
              if (res.data.status === 200) {
                console.log("set data")
                console.log(res.data.data)
                // commit("setloading",false);
                commit("setlist_contact", res.data.data);
                
              }
              if (res.data.status === 401) {
                console.log("error")
                // commit("setloading",false);
                // commit("seterrorsnack",true);
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            alert('เกิดข้อผิดพลาดในการเชื่อมต่อ');
            // error.response.status Check status code
          })
          .finally(() => {
            // กระทำที่จะทำเสมอ
          });
      },

      fetchNews({ commit, state }) {
        // ส่งคำขอ GET ไปยัง API เพื่อรับข้อมูลข่าว
        axios.get(state.data.url_lifelink + "news/visitor/list", {
          params: {
            building_id: 6,
            limit: 5
          }
        })
        .then(response => {
          // ตรวจสอบว่าคำขอเสร็จสมบูรณ์และมีข้อมูลในการตอบกลับ
          if (response.status === 200 && response.data) {
            // เซ็ตข้อมูลข่าวที่ได้รับลงในตัวแปร newsList
            console.log("news")
            console.log(response.data)
            commit("setlist_news", response.data);
          }
        })
        .catch(error => {
          // หากเกิดข้อผิดพลาดในการเรียก API
          console.error('Error fetching news:', error);
        });
      },

      today_data({ commit, state }) {
        // ส่งคำขอ GET ไปยัง API เพื่อรับข้อมูลข่าว
        axios.get(state.data.url_lifelink + "today", {
          params: {
            latitude: 13.75,
            longitude: 100.5167,
            language: 'th'
          }
        })
        .then(response => {
          // ตรวจสอบว่าคำขอเสร็จสมบูรณ์และมีข้อมูลในการตอบกลับ
          if (response.status === 200 && response.data) {
            // เซ็ตข้อมูลข่าวที่ได้รับลงในตัวแปร newsList
            console.log("today")
            console.log(response.data)
            commit("setlist_today", response.data);
          }
        })
        .catch(error => {
          // หากเกิดข้อผิดพลาดในการเรียก API
          console.error('Error fetching news:', error);
        });
      },

      register({ commit, state }) {
        // ตรวจสอบให้แน่ใจว่า state.data.url_spare และ state.data.token มีค่าถูกต้อง
        let payload = new FormData()

        const parts = state.data.hiv_photo.split(",");
        state.data.hiv_photo_send = parts[1]

        const now = new Date();
        const currentDate = now.toLocaleString('th-TH', {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
      });

      const currentDateend = now.toLocaleString('th-TH', {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    });

    for (let i = 0; i < state.data.list_contact_data.length; i++) {

      if(state.data.list_contact_data[i].name == state.data.company){
  
        state.data.user_id = state.data.list_contact_data[i].id

      }
    }


        let listcontact_data = [{"firstname":state.data.firstname,"lastname":state.data.lastname,"visitor_cardid":"000","tel":"0000000000","id_card":state.data.id_card}]
        payload.append('firstname',state.data.firstname)
        payload.append('lastname',state.data.lastname)
        payload.append('id_card',state.data.id_card)
        // console.log(listcontact_data)
        // payload.append('contect', [{"firstname":"chayanon","lastname":"baimuang","visitor_cardid":"000","tel":"0000000000","id_card":"11007777"}]);
        if(state.data.id_photo == ""){
          const parts1 = state.data.id_card_null.split(",");
          state.data.id_card_null = parts[1]

          payload.append('imgfile',state.data.id_card_null);
        }else{
          const parts2 = state.data.id_photo.split(",");
          state.data.id_photo_send = parts[1]
          payload.append('imgfile',state.data.id_photo_send);
        }
        payload.append('use_img', 1);
        payload.append('user_id', state.data.user_id);
        payload.append('gate', "kiosk window ชั้น G");
        payload.append('user_car', "0");
        payload.append('car_id', "");
        payload.append('user_email', "support@lifelink.co.th");
        payload.append('company', "");
        payload.append('email', "");
        payload.append('date_time_use', currentDate);
        payload.append('date_time_end', currentDateend + " 00:00");
      
        payload.append('covit', state.data.hiv_photo_send);
        payload.append('company_id', "1");
        payload.append('use_img', "1");
        payload.append('topic', "พบ " + state.data.company);
      
        // ใช้ headers ถ้าคุณต้องการส่ง Authorization token
        // const headers = {"Authorization": state.data.token}
      
        axios.post(state.data.url + "fastregister_v3_3", payload,{
          timeout: 5000 // กำหนด timeout เป็น 5000 มิลลิวินาที (5 วินาที)
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 200) {
                console.log("ลงทะเบียนแล้ว 200")
                commit("setregister")
              }
              if (res.data.status === 401) {
                console.log("error")
                console.log(res)
     
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            actions.register_wtc({ commit, state })
            // error.response.status Check status code
          })
          .finally(() => {
            // กระทำที่จะทำเสมอ
          });
      },

      register_wtc({ commit, state }) {
        // ตรวจสอบให้แน่ใจว่า state.data.url_spare และ state.data.token มีค่าถูกต้อง
        let payload = new FormData()

        const parts = state.data.hiv_photo.split(",");
        state.data.hiv_photo_send = parts[1]

        const now = new Date();
        const currentDate = now.toLocaleString('th-TH', {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
      });

      const currentDateend = now.toLocaleString('th-TH', {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    });

    for (let i = 0; i < state.data.list_contact_data.length; i++) {

      if(state.data.list_contact_data[i].name == state.data.company){

        state.data.user_id = state.data.list_contact_data[i].id

      }
    }


        let listcontact_data = [{"firstname":state.data.firstname,"lastname":state.data.lastname,"visitor_cardid":"000","tel":"0000000000","id_card":state.data.id_card}]
        payload.append('firstname',state.data.firstname)
        payload.append('lastname',state.data.lastname)
        payload.append('id_card',state.data.id_card)
        // console.log(listcontact_data)
        // payload.append('contect', [{"firstname":"chayanon","lastname":"baimuang","visitor_cardid":"000","tel":"0000000000","id_card":"11007777"}]);
        if(state.data.id_photo == ""){
          console.log("imgfile")

          const parts1 = state.data.id_card_null.split(",");
          state.data.id_card_null = parts[1]
          console.log(state.data.id_card_null)
          payload.append('imgfile',state.data.id_card_null);
        }else{
          const parts2 = state.data.id_photo.split(",");
          state.data.id_photo_send = parts[1]
          payload.append('imgfile',state.data.id_photo);
        }
        payload.append('use_img', 1);
        payload.append('user_id', state.data.user_id);
        payload.append('gate', "kiosk window ชั้น G");
        payload.append('user_car', "0");
        payload.append('car_id', "");
        payload.append('user_email', "support@lifelink.co.th");
        payload.append('company', "");
        payload.append('email', "");
        payload.append('date_time_use', currentDate);
        payload.append('date_time_end', currentDateend + " 00:00");
        console.log(currentDate)
        console.log(currentDateend + " 23:59")
        console.log(state.data.hiv_photo)
        payload.append('covit', state.data.hiv_photo);
        payload.append('company_id', "1");
        payload.append('use_img', "1");
        payload.append('topic', "พบ " + state.data.company);
      
        // ใช้ headers ถ้าคุณต้องการส่ง Authorization token
        // const headers = {"Authorization": state.data.token}
      
        axios.post(state.data.url_spare1 + "fastregister_v3_3", payload,{
          timeout: 5000 // กำหนด timeout เป็น 5000 มิลลิวินาที (5 วินาที)
        })
          .then((res) => {
            console.log("set")
            if (res.status === 200) {
              if (res.data.status === 200) {

                console.log("ลงทะเบียนแล้ว 200")
                commit("setregister")
  
              }
              if (res.data.status === 401) {
                console.log("error")
                console.log(res)
                // commit("setloading",false);
                // commit("seterrorsnack",true);
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            alert('เกิดข้อผิดพลาดในการเชื่อมต่อ');
            commit("setloading");
            // error.response.status Check status code
          })
          .finally(() => {
            // กระทำที่จะทำเสมอ
          });
      },

      reset({ commit, state }) {
        commit("setreset");
      },

}

const mutations = {

    setlist_contact(state, item) {
        state.data.list_contact = []; // รีเซ็ต list_contact เป็น array ว่าง
      
        for (let i = 0; i < item.length; i++) {

          state.data.list_contact.push(item[i].name); // เพิ่มชื่อของแต่ละรายการลงใน list_contact
          state.data.list_contact_data.push(item[i]);
        }
      },

      setlist_news(state, item) {
        state.data.list_subittle_news = [];
        state.data.list_image_news = [] 
      
        for (let i = 0; i < item.length; i++) {

          state.data.list_subittle_news.push(item[i]); 


        }
      },

      setlist_today(state, item) {
        console.log("today")
        console.log(item);

          console.log("air_pollution")
          console.log(item.air_pollution);
          state.data.pm_list = item.air_pollution
          state.data.pm_list.pm2_5 = state.data.pm_list.pm2_5.toFixed(0);

          console.log("weather")
          console.log(item.weather);
          state.data.today_list = item.weather
          state.data.today_list.temperature = state.data.today_list.temperature.toFixed(0);
      
    
      },
      setreset(state, item) {
      
          state.data.firstname = ""
          state.data.lastname = ""
          state.data.id_card = ""
          state.data.id_photo = ""
          state.data.company = ""
          state.data.hiv_photo = "" 
    
      },
      setregister(state, item){
        console.log("เข้า commit")
        state.data.register_status = true
        state.data.loading = false

      },
      setloading(state, item){
        state.data.loading = false
      }
   
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,    
}