// import Vue from 'vue'
// import * as Vue from 'vue'
// import Vuex from 'vuex'
// import datas from '@/store/modules/datas'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   // state: {
//   // },
//   // mutations: {
//   // },
//   // actions: {
//   // },
//   modules: {
//     datas
//   }
// })


// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import { createStore } from "vuex";
import axios from "axios";
import datas from '@/store/modules/datas'

export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    datas
    }
})
