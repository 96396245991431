import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Camera from "simple-vue-camera"
import EN from './locale/en.json'
import TH from './locale/th.json'
import { createI18n } from 'vue-i18n'
import { loadFonts } from './plugins/webfontloader'


loadFonts()

const i18n = createI18n({
  locale: 'TH',
  messages: {
    TH: TH,
    EN: EN
  }
})

createApp(App)
  .use(router)
  .use(i18n)
  .use(store)
  .use(vuetify)
  .component("camera", Camera)
  .mount('#app')
