<template>
  <registercompo @picture-taken="data.hiv_photo = $event"/>
</template>

<script>
import { defineComponent } from 'vue';
import {
    mapState
} from 'vuex';

// Components
import registercompo from '../components/RegisterPage.vue';

export default defineComponent({
  name: 'register',

  components: {
    registercompo,
  },

  data(){
    return{
      
    }
  },
  computed: {
        ...mapState('datas', {
            data: 'data'
        })
    },
});
</script>