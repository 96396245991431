<template>
    <registerresultcompo />
  </template>
  

<script>
import { defineComponent } from 'vue';

// Components
import registerresultcompo from '../components/Resultdata.vue';

export default defineComponent({
  name: 'registerresult',

  components: {
    registerresultcompo,
  },
});
</script>