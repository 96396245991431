<template>
  <cardreadercompo />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import cardreadercompo from '../components/Cardreader.vue';

export default defineComponent({
  name: 'cardreader',

  components: {
    cardreadercompo,
  },
});
</script>